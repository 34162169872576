/* jetbrains-mono-100 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-300 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-200 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-regular - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-regular.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-500 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-600 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-700 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-800 - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-100italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-100italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-200italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-200italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-300italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-300italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-500italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-500italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-600italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-600italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-700italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-700italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}
/* jetbrains-mono-800italic - latin-ext_latin_cyrillic-ext_cyrillic */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: italic;
    font-weight: 800;
    src: url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.woff') format('woff'), /* Modern Browsers */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/jetbrains-mono-v6-latin-ext_latin_cyrillic-ext_cyrillic-800italic.svg#JetBrainsMono') format('svg'); /* Legacy iOS */
}