@import "@blueprintjs/core/lib/scss/variables";

.result-page-content {
  min-height: calc(100vh - 50px);
  height: calc(100vh - 50px);
  overflow: auto;
  padding: 20px;
  background-color: $pt-app-background-color;

  .search-form {
    padding: 2rem 15% 2rem 15%;
  }

  .domain-info {
    margin-top: 2rem;

    .domain-mugshot {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
    }

    .tabs-container {
      .bp4-tab-panel {
        width: 100%;
      }

      .domain-info-tab {
        min-height: 12rem;
      }
    }
  }

  .result-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 1rem;

    .profile-wrapper {
      padding: 0.5rem;
    }

    .websites-block {

      .websites-header {
        text-align: center;
      }
    }



    .histograms-toolbar {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem;
      margin-bottom: 1rem;

      .histograms-toolbar-title {
        font-weight: 500;
        line-height: 24px;
      }
    }

    .histograms {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

}