@import "@blueprintjs/core/lib/scss/variables";

.histogram-block {
  padding: 0.75rem;
  font-size: 0.75rem;

  .histogram-block-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .histogram-block-title {
      font-weight: 500;
      font-size: 0.75rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .histogram-content {
    .item-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 1.6em;
      padding: 2px;
      cursor: pointer;

      .item-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 8px;
        padding-left: 4px;
      }
    }
    .item-row:first-child {
      margin-top: 10px;
    }

    .item-row:nth-child(2n+1) {
      background-color: rgba(92, 112, 128, 0.15);
    }
  }





}