@import "@blueprintjs/core/lib/scss/variables";

.website-card {
  height: 100%;

  .website-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .website-link {
      font-size: 1.125rem;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .address-header {
    font-size: 0.75rem;
    font-weight: bold;
  }

  .profile-content {
    padding-top: 0.5rem;

    .snapshot {
      max-width: 100%;
      height: auto;
      -webkit-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      -moz-box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
      box-shadow: 0 5px 10px 2px rgba(34, 60, 80, 0.2);
    }

    .website-title {
      font-size: 1rem;
      font-weight: bold;
    }

    .certificate-block {
      width: 100%;
      overflow: hidden;

      table {
        width: 100%;
        font-size: 0.625rem;

        .certificate-key {
          font-weight: 700;
        }
      }
    }
  }

  .technologies {
    padding-top: 0.5rem;

    div {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem;
    }
  }
}