@import "@blueprintjs/core/lib/scss/variables";

body {
  margin: 0;
  font-family: "JetBrains Mono", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: 100vh;
}

.App {
  height: 100vh;
}

.page-content {
  height: calc(100vh - 50px);
  background-color: $pt-app-background-color;
}

.bp4-dark .page-content {
  background-color: $pt-dark-app-background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
