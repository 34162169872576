@import "@blueprintjs/core/lib/scss/variables";

.index-page-content {
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .search-form {
        width: 70%;
        margin-top: 10rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .hackerone-button {
        margin: 2rem 2rem 0 2rem;
        font-weight: 500;
        line-height: 1.5rem;

        .sample-name {
            color: $pt-intent-success;
        }
    }
}

